import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TbCheck } from 'react-icons/tb';
import { RxDash } from 'react-icons/rx';
import { Form } from '@unform/web';
import { IoIosArrowForward } from 'react-icons/io';
import { RiWhatsappLine } from 'react-icons/ri';
import Lottie, { Options } from 'react-lottie';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import ReactApexChart from 'react-apexcharts';

import Swal from 'sweetalert2';
import { ApexOptions } from 'apexcharts';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';
import InputMask from '~/components/InputMask';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputFile from '~/components/InputFile';

import logo from '~/assets/logos/white-logo.svg';
import success from '~/assets/animations/success.json';
import Loading from '~/components/Loading';

interface IParams {
  step: string;
  subStep?: string;
}

interface IFormData {
  project_type: string;
  domain: string;
  server: string;
  budget: string;
  name: string;
  email: string;
  celphone: string;
  file: string;
  observation: string;
  deadline: string;
}

interface IBudget {
  projectTypes: string[] | string;
  visualIdentity: string;
  hasDomain: string;
  domain: string;
  hasServer: string;
  server: string;
  hasDesign: string;
  hasDeveloper: string;
  deadline: string;
  budget: string;
  name: string;
  email: string;
  celphone: string;
  hasInspiration: string;
  files: File[];
  observation: string;
}

const MakeBudget: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [title, setTitle] = useState('Sobre seu projeto');
  const [question, setQuestion] = useState('Que tipo de projeto você deseja?');
  const [options, setOptions] = useState<IOption[]>([]);
  const [checkBoxError, setCheckBoxError] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [budget, setBudget] = useState<IBudget>({
    projectTypes: '-',
    visualIdentity: '-',
    hasDomain: '-',
    domain: '-',
    hasServer: '-',
    server: '-',
    hasDesign: '-',
    hasDeveloper: '-',
    deadline: '-',
    budget: '-',
    name: '',
    email: '',
    celphone: '-',
    hasInspiration: '-',
    files: [],
    observation: '-',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params.step === 'sobre-seu-projeto') {
      setTitle('Sobre seu projeto');

      switch (params.subStep) {
        case 'voce-possui-uma-identidade-visual-para-esse-projeto':
          setQuestion('Você possui uma identidade visual para esse projeto?');
          setOptions([
            {
              label: 'Sim',
              value: 'Sim',
              selected: false,
            },
            {
              label: 'Não',
              value: 'Não',
              selected: false,
            },
          ]);
          break;
        case 'voce-possui-dominio-web-url-para-seu-projeto':
          setQuestion('Você possui alguma URL para seu projeto?');
          setOptions([
            {
              label: 'Sim',
              value: 'Sim',
              selected: false,
            },
            {
              label: 'Não',
              value: 'Não',
              selected: false,
            },
          ]);
          break;
        case 'voce-possui-servidor-para-seu-projeto':
          setQuestion('Você possui servidor para seu projeto?');
          setOptions([
            {
              label: 'Sim',
              value: 'Sim',
              selected: false,
            },
            {
              label: 'Não',
              value: 'Não',
              selected: false,
            },
          ]);
          break;
        case 'voce-possui-design-para-esse-projeto':
          setQuestion('Você possui design para esse projeto?');
          setOptions([
            {
              label: 'Sim',
              value: 'Sim',
              selected: false,
            },
            {
              label: 'Não',
              value: 'Não',
              selected: false,
            },
          ]);
          break;
        case 'voce-possui-programador-para-esse-projeto':
          setQuestion('Você possui programador para esse projeto?');
          setOptions([
            {
              label: 'Sim',
              value: 'Sim',
              selected: false,
            },
            {
              label: 'Não',
              value: 'Não',
              selected: false,
            },
          ]);
          break;
        case 'em-quanto-tempo-precisa-desse-projeto-pronto':
          setQuestion('Em quanto tempo precisa desse projeto pronto?');
          setOptions([
            {
              label: 'Menos de uma semana',
              value: 'Menos de uma semana',
              selected: false,
            },
            {
              label: 'Uma semana',
              value: 'Uma semana',
              selected: false,
            },
            {
              label: 'Duas semanas',
              value: 'Duas semanas',
              selected: false,
            },
            {
              label: 'Três semanas',
              value: 'Três semanas',
              selected: false,
            },
            {
              label: 'Um mês',
              value: 'Um mês',
              selected: false,
            },
            {
              label: 'Outro',
              value: 'Outro',
              selected: false,
            },
          ]);
          break;
        default:
          setQuestion('Que tipo de projeto você deseja?');
          setOptions([
            {
              label: 'Artes Gráficas',
              value: 'Artes Gráficas',
              selected: false,
            },
            {
              label: 'Banners',
              value: 'Banners',
              selected: false,
            },
            {
              label: 'Outdoors',
              value: 'Outdoors',
              selected: false,
            },
            {
              label: 'Panfletos',
              value: 'Panfletos',
              selected: false,
            },
            {
              label: 'Cartões de visita',
              value: 'Cartões de visita',
              selected: false,
            },
            {
              label: 'Mídia social',
              value: 'Mídia social',
              selected: false,
            },
            {
              label: 'Facebook',
              value: 'Facebook',
              selected: false,
            },
            {
              label: 'Instagram',
              value: 'Instagram',
              selected: false,
            },
            {
              label: 'Google ADS',
              value: 'Google ADS',
              selected: false,
            },
            {
              label: 'Identidade visual',
              value: 'Identidade visual',
              selected: false,
            },
            {
              label: 'Logo, logomarca ou logotipo',
              value: 'Identidade visual',
              selected: false,
            },
            {
              label: 'Layout',
              value: 'Layout',
              selected: false,
            },
            {
              label: 'Interface do usuário',
              value: 'Interface do usuário',
              selected: false,
            },
            {
              label: 'Experiência do usuário',
              value: 'Experiência do usuário',
              selected: false,
            },
            {
              label: 'Site',
              value: 'Site',
              selected: false,
            },
            {
              label: 'Páginas na internet',
              value: 'Páginas na internet',
              selected: false,
            },
            {
              label: 'Landpage',
              value: 'Landpage',
              selected: false,
            },
            {
              label: 'Sistema',
              value: 'Sistema',
              selected: false,
            },
            {
              label: 'Aplicativos',
              value: 'Aplicativos',
              selected: false,
            },
            {
              label: 'Aplicativo para Android',
              value: 'Aplicativo para Android',
              selected: false,
            },
            {
              label: 'Aplicativo para IOS',
              value: 'Aplicativo para IOS',
              selected: false,
            },
          ]);
          break;
      }
    } else if (params.step === 'dados-para-contato') {
      setTitle('Dados para contato');
      setQuestion('Preencha seus dados para entrarmos em contato 😀');
    } else if (params.step === 'informacoes-adicionais') {
      setTitle('Informações adicionais');
      setQuestion('Possui alguma inspiração?');
      setTimeout(() => {
        setOptions([
          {
            label: 'Sim',
            value: 'Sim',
            selected: false,
          },
          {
            label: 'Não',
            value: 'Não',
            selected: false,
          },
        ]);
      }, 100);
    }
  }, [params]);

  const handleClickSkip = useCallback(
    (budgetData?: IBudget, realSkip?: boolean) => {
      setOptions([]);
      if (params.step === 'sobre-seu-projeto') {
        if (budgetData && typeof budgetData.projectTypes === 'object') {
          const GraphicDesign = !!budgetData.projectTypes.find(
            (projectType) =>
              projectType === 'Artes Gráficas' ||
              projectType === 'Banners' ||
              projectType === 'Outdoors' ||
              projectType === 'Panfletos' ||
              projectType === 'Cartões de visita'
          );
          // IDV

          const socialMedia = !!budgetData.projectTypes.find(
            (projectType) =>
              projectType === 'Mídia social' ||
              projectType === 'Facebook' ||
              projectType === 'Instagram' ||
              projectType === 'Google ADS'
          );
          // IDV URL Design

          const visualIdentity = !!budgetData.projectTypes.find(
            (projectType) =>
              projectType === 'Identidade visual' ||
              projectType === 'Logo, logomarca ou logotipo' ||
              projectType === 'Layout' ||
              projectType === 'Interface do usuário' ||
              projectType === 'Experiência do usuário'
          );
          //

          const site = !!budgetData.projectTypes.find(
            (projectType) =>
              projectType === 'Site' ||
              projectType === 'Páginas na internet' ||
              projectType === 'Landpage' ||
              projectType === 'Sistema'
          );
          // IDV URL SERVIDOR DESIGN

          const app = !!budgetData.projectTypes.find(
            (projectType) =>
              projectType === 'Aplicativos' ||
              projectType === 'Aplicativo para Android' ||
              projectType === 'Aplicativo para IOS'
          );
          // IDV URL SERVIDOR DESIGN

          const idv = GraphicDesign || socialMedia || site || app;
          const url = socialMedia || site || app;
          const server = site || app;

          if (params.subStep === 'que-tipo-de-projeto-voce-deseja') {
            if (idv && !visualIdentity) {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-uma-identidade-visual-para-esse-projeto`
              );
            } else if (url) {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-dominio-web-url-para-seu-projeto`
              );
            } else if (server) {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-servidor-para-seu-projeto`
              );
            } else {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/em-quanto-tempo-precisa-desse-projeto-pronto`
              );
            }
          }

          if (
            params.subStep ===
            'voce-possui-uma-identidade-visual-para-esse-projeto'
          ) {
            if (url) {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-dominio-web-url-para-seu-projeto`
              );
            } else if (server) {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-servidor-para-seu-projeto`
              );
            } else {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/em-quanto-tempo-precisa-desse-projeto-pronto`
              );
            }
          }

          if (
            params.subStep === 'voce-possui-dominio-web-url-para-seu-projeto'
          ) {
            if (server) {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-servidor-para-seu-projeto`
              );
            } else {
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/em-quanto-tempo-precisa-desse-projeto-pronto`
              );
            }
          }

          if (params.subStep === 'voce-possui-servidor-para-seu-projeto') {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/em-quanto-tempo-precisa-desse-projeto-pronto`
            );
          }

          if (
            params.subStep === 'em-quanto-tempo-precisa-desse-projeto-pronto'
          ) {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/dados-para-contato`
            );
          }
        } else {
          switch (params.subStep) {
            case 'voce-possui-uma-identidade-visual-para-esse-projeto':
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-dominio-web-url-para-seu-projeto`
              );
              break;
            case 'voce-possui-dominio-web-url-para-seu-projeto':
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-servidor-para-seu-projeto`
              );
              break;
            case 'voce-possui-servidor-para-seu-projeto':
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-design-para-esse-projeto`
              );
              break;
            case 'voce-possui-design-para-esse-projeto':
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-programador-para-esse-projeto`
              );
              break;
            case 'voce-possui-programador-para-esse-projeto':
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/em-quanto-tempo-precisa-desse-projeto-pronto`
              );
              break;
            case 'em-quanto-tempo-precisa-desse-projeto-pronto':
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/dados-para-contato`
              );
              break;
            default:
              history.push(
                `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-uma-identidade-visual-para-esse-projeto`
              );
              break;
          }
        }
      } else if (params.step === 'dados-para-contato') {
        history.push(
          `${process.env.PUBLIC_URL}/quero-um-projeto/informacoes-adicionais`
        );
      } else if (params.step === 'informacoes-adicionais') {
        if (realSkip) {
          history.push(
            `${process.env.PUBLIC_URL}/quero-um-projeto/informacoes-adicionais/solicitacao-recebida`
          );
        } else {
          formRef.current?.submitForm();
        }
      }
    },
    [history, params.step, params.subStep]
  );

  const handleClickBack = useCallback(() => {
    setOptions([]);
    if (params.step === 'sobre-seu-projeto') {
      if (typeof budget.projectTypes === 'object') {
        const GraphicDesign = !!budget.projectTypes.find(
          (projectType) =>
            projectType === 'Artes Gráficas' ||
            projectType === 'Banners' ||
            projectType === 'Outdoors' ||
            projectType === 'Panfletos' ||
            projectType === 'Cartões de visita'
        );
        // IDV

        const socialMedia = !!budget.projectTypes.find(
          (projectType) =>
            projectType === 'Mídia social' ||
            projectType === 'Facebook' ||
            projectType === 'Instagram' ||
            projectType === 'Google ADS'
        );
        // IDV URL Design

        const visualIdentity = !!budget.projectTypes.find(
          (projectType) =>
            projectType === 'Identidade visual' ||
            projectType === 'Logo, logomarca ou logotipo' ||
            projectType === 'Layout' ||
            projectType === 'Interface do usuário' ||
            projectType === 'Experiência do usuário'
        );
        //

        const site = !!budget.projectTypes.find(
          (projectType) =>
            projectType === 'Site' ||
            projectType === 'Páginas na internet' ||
            projectType === 'Landpage' ||
            projectType === 'Sistema'
        );
        // IDV URL SERVIDOR DESIGN

        const app = !!budget.projectTypes.find(
          (projectType) =>
            projectType === 'Aplicativos' ||
            projectType === 'Aplicativo para Android' ||
            projectType === 'Aplicativo para IOS'
        );
        // IDV URL SERVIDOR DESIGN

        const idv = GraphicDesign || socialMedia || site || app;
        const url = socialMedia || site || app;
        const server = site || app;

        if (params.subStep === 'em-quanto-tempo-precisa-desse-projeto-pronto') {
          if (server) {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-servidor-para-seu-projeto`
            );
          } else if (url) {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-dominio-web-url-para-seu-projeto`
            );
          } else if (idv && !visualIdentity) {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-uma-identidade-visual-para-esse-projeto`
            );
          } else {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/que-tipo-de-projeto-voce-deseja`
            );
          }
        }

        if (params.subStep === 'voce-possui-servidor-para-seu-projeto') {
          if (url) {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-dominio-web-url-para-seu-projeto`
            );
          } else if (idv && !visualIdentity) {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-uma-identidade-visual-para-esse-projeto`
            );
          } else {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/que-tipo-de-projeto-voce-deseja`
            );
          }
        }

        if (params.subStep === 'voce-possui-dominio-web-url-para-seu-projeto') {
          if (idv && !visualIdentity) {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-uma-identidade-visual-para-esse-projeto`
            );
          } else {
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/que-tipo-de-projeto-voce-deseja`
            );
          }
        }

        if (
          params.subStep ===
          'voce-possui-uma-identidade-visual-para-esse-projeto'
        ) {
          history.push(
            `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/que-tipo-de-projeto-voce-deseja`
          );
        }
      } else {
        switch (params.subStep) {
          case 'voce-possui-uma-identidade-visual-para-esse-projeto':
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/que-tipo-de-projeto-voce-deseja`
            );
            break;
          case 'voce-possui-dominio-web-url-para-seu-projeto':
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-uma-identidade-visual-para-esse-projeto`
            );
            break;
          case 'voce-possui-servidor-para-seu-projeto':
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-dominio-web-url-para-seu-projeto`
            );
            break;
          case 'voce-possui-design-para-esse-projeto':
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-servidor-para-seu-projeto`
            );
            break;
          case 'voce-possui-programador-para-esse-projeto':
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-design-para-esse-projeto`
            );
            break;
          case 'em-quanto-tempo-precisa-desse-projeto-pronto':
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/voce-possui-programador-para-esse-projeto`
            );
            break;
          default:
            history.push(
              `${process.env.PUBLIC_URL}/quero-um-projeto/${params.step}/que-tipo-de-projeto-voce-deseja`
            );
            break;
        }
      }
    } else if (params.step === 'dados-para-contato') {
      history.push(
        `${process.env.PUBLIC_URL}/quero-um-projeto/sobre-seu-projeto/em-quanto-tempo-precisa-desse-projeto-pronto`
      );
    } else if (params.step === 'informacoes-adicionais') {
      history.push(
        `${process.env.PUBLIC_URL}/quero-um-projeto/dados-para-contato`
      );
    }
  }, [budget.projectTypes, history, params.step, params.subStep]);

  const handleChange = useCallback((optionsData) => {
    if (optionsData.length > 0) {
      setOptions(optionsData);
    }
  }, []);

  const handleChangeFiles = useCallback((filesData) => {
    setFiles(filesData);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setCheckBoxError('');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          checkbox: Yup.string().when('$checkbox', {
            is: (checkbox: boolean) => checkbox,
            then: Yup.string().required('Escolha ao menos uma das opções'),
            otherwise: Yup.string(),
          }),
          domain: Yup.string().when('$domain', {
            is: (domainCheck: boolean) => domainCheck,
            then: Yup.string().required('O domínio é obrigatório'),
            otherwise: Yup.string(),
          }),
          server: Yup.string().when('$server', {
            is: (serverCheck: boolean) => serverCheck,
            then: Yup.string().required('O nome do servidor é obrigatório'),
            otherwise: Yup.string(),
          }),
          name: Yup.string().when('$name', {
            is: (nameCheck: boolean) => nameCheck,
            then: Yup.string().required('O nome é obrigatório'),
            otherwise: Yup.string(),
          }),
          email: Yup.string().when('$email', {
            is: (emailCheck: boolean) => emailCheck,
            then: Yup.string().required('O e-mail é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkbox:
              params.step === 'sobre-seu-projeto' &&
              options.filter((option) => option.selected).length === 0,
            domain:
              params.subStep ===
                'voce-possui-dominio-web-url-para-seu-projeto' &&
              options.filter(
                (option) => option.value === 'Sim' && option.selected
              ).length > 0,
            server:
              params.subStep === 'voce-possui-servidor-para-seu-projeto' &&
              options.filter(
                (option) => option.value === 'Sim' && option.selected
              ).length > 0,
            name: params.step === 'dados-para-contato',
            email: params.step === 'dados-para-contato',
          },
        });

        const newBudget = budget;
        if (params.subStep === 'que-tipo-de-projeto-voce-deseja') {
          const projectTypes: string[] = [];
          options.forEach((option) => {
            if (option.selected) {
              projectTypes.push(option.value as string);
            }
          });
          newBudget.projectTypes = projectTypes;
          setBudget(newBudget);
        }

        if (
          params.subStep ===
          'voce-possui-uma-identidade-visual-para-esse-projeto'
        ) {
          const optionData = options.find((option) => option.selected);
          newBudget.visualIdentity = optionData
            ? (optionData.value as string)
            : '-';
          setBudget(newBudget);
        }

        if (params.subStep === 'voce-possui-dominio-web-url-para-seu-projeto') {
          const optionData = options.find((option) => option.selected);
          newBudget.hasDomain = optionData ? (optionData.value as string) : '-';
          newBudget.domain = optionData?.value === 'Sim' ? data.domain : '-';
          setBudget(newBudget);
        }

        if (params.subStep === 'voce-possui-servidor-para-seu-projeto') {
          const optionData = options.find((option) => option.selected);
          newBudget.hasServer = optionData ? (optionData.value as string) : '-';
          newBudget.hasServer = optionData?.value === 'Sim' ? data.server : '-';
          setBudget(newBudget);
        }

        if (params.subStep === 'voce-possui-design-para-esse-projeto') {
          const optionData = options.find((option) => option.selected);
          newBudget.hasDesign = optionData ? (optionData.value as string) : '-';
          setBudget(newBudget);
        }

        if (params.subStep === 'voce-possui-programador-para-esse-projeto') {
          const optionData = options.find((option) => option.selected);
          newBudget.hasDeveloper = optionData
            ? (optionData.value as string)
            : '-';
          setBudget(newBudget);
        }

        if (params.subStep === 'em-quanto-tempo-precisa-desse-projeto-pronto') {
          const optionData = options.find((option) => option.selected);
          let deadline = '-';
          if (optionData) {
            if (optionData.value === 'Outro') {
              deadline = data.deadline;
            } else {
              deadline = optionData.value as string;
            }
          }
          newBudget.deadline = deadline;
          setBudget(newBudget);
        }

        if (params.step === 'dados-para-contato') {
          newBudget.name = data.name;
          newBudget.email = data.email;
          newBudget.celphone = data.celphone || '-';
          setBudget(newBudget);
        }

        if (params.step === 'informacoes-adicionais') {
          setLoading(true);
          const optionData = options.find((option) => option.selected);
          const hasInspiration = optionData
            ? (optionData.value as string)
            : '-';
          const budgetData = {
            ...newBudget,
            hasInspiration,
            files,
            observation: data.observation || '-',
          };

          const formData = new FormData();
          formData.append('budget', budgetData.budget);
          formData.append('celphone', budgetData.celphone);
          formData.append('deadline', budgetData.deadline);
          formData.append('domain', budgetData.domain);
          formData.append('email', budgetData.email);
          formData.append('hasDesign', budgetData.hasDesign);
          formData.append('hasDeveloper', budgetData.hasDeveloper);
          formData.append('hasDomain', budgetData.hasDomain);
          formData.append('hasInspiration', budgetData.hasInspiration);
          formData.append('hasServer', budgetData.hasServer);
          formData.append('name', budgetData.name);
          formData.append('observation', budgetData.observation);
          formData.append('server', budgetData.server);
          formData.append('visualIdentity', budgetData.visualIdentity);
          if (typeof budgetData.projectTypes === 'object') {
            budgetData.projectTypes.forEach((projectType) =>
              formData.append('projectTypes', projectType)
            );
          } else {
            formData.append('projectTypes', budgetData.projectTypes);
          }
          budgetData.files.forEach((file) => formData.append('files', file));

          await api.post('leads', formData);
        }
        handleClickSkip(newBudget, true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.checkbox) {
            setCheckBoxError(errors.checkbox);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      } finally {
        setLoading(false);
      }
    },
    [budget, files, handleClickSkip, options, params.step, params.subStep]
  );

  const series = useMemo(() => {
    switch (params.step) {
      case 'dados-para-contato':
        return [66];
      case 'informacoes-adicionais':
        return [100];
      default:
        return [33];
    }
  }, [params.step]);

  const optionsChart = useMemo<ApexOptions>(
    () => ({
      chart: {
        type: 'radialBar',
        width: '100%',
        height: '100%',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '45%',
          },
          dataLabels: {
            show: false,
          },
          track: {
            background: '#CFE5FF',
          },
        },
      },
      colors: ['#0075FF'],
      stroke: {
        lineCap: 'round',
      },
    }),
    []
  );

  return (
    <Container className="py-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="box pb-5 d-none d-lg-flex h-100">
              <div className="p-5">
                <img src={logo} alt="Logo" />
                <div className="detail" />
                <h1 className="h3 fw-normal pb-5 mt-2">
                  Sua grande <b>idea</b> começa aqui
                </h1>
                <div className="position-relative mt-5">
                  <Link
                    to={`${process.env.PUBLIC_URL}/quero-um-projeto/sobre-seu-projeto/que-tipo-de-projeto-voce-deseja`}
                    className="d-flex align-items-center active"
                  >
                    <span className="d-flex align-items-center justify-content-center rounded-circle me-4">
                      <TbCheck size={24} color="#fff" />
                    </span>
                    <span>Sobre seu projeto</span>
                  </Link>
                  <div className="position-absolute first" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/quero-um-projeto/dados-para-contato`}
                    className={`d-flex align-items-center ${
                      params.step !== 'sobre-seu-projeto' ? 'active' : ''
                    }`}
                  >
                    <span className="d-flex align-items-center justify-content-center rounded-circle me-4">
                      {params.step !== 'sobre-seu-projeto' ? (
                        <TbCheck size={24} color="#fff" />
                      ) : (
                        <RxDash size={24} color="#fff" />
                      )}
                    </span>
                    <span>Dados para contato</span>
                  </Link>
                  <div className="position-absolute second" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/quero-um-projeto/informacoes-adicionais`}
                    className={`d-flex align-items-center ${
                      params.step === 'informacoes-adicionais' ? 'active' : ''
                    }`}
                  >
                    <span className="d-flex align-items-center justify-content-center rounded-circle me-4">
                      {params.step === 'informacoes-adicionais' ? (
                        <TbCheck size={24} color="#fff" />
                      ) : (
                        <RxDash size={24} color="#fff" />
                      )}
                    </span>
                    <span>Informações adicionais</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-flex d-lg-none justify-content-between align-items-center pt-4 pe-3">
              {params.subStep !== 'solicitacao-recebida' && (
                <>
                  <div className="circle position-relative d-flex align-items-center justify-content-center">
                    <div className="graph d-flex align-items-center justify-content-center position-absolute">
                      <ReactApexChart
                        options={optionsChart}
                        series={series}
                        type="radialBar"
                      />
                    </div>
                    <small className="fw-semibold position-absolute">
                      <small>
                        {params.step === 'sobre-seu-projeto' && 1}
                        {params.step === 'dados-para-contato' && 2}
                        {params.step === 'informacoes-adicionais' && 3} de 3
                      </small>
                    </small>
                  </div>
                  {params.subStep !== 'que-tipo-de-projeto-voce-deseja' &&
                    params.step !== 'dados-para-contato' && (
                      <button
                        type="button"
                        className="border-0 bg-transparent fw-semibold btn-skip"
                        onClick={() => handleClickSkip(budget)}
                      >
                        Pular
                      </button>
                    )}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-8 pt-5 ps-lg-5 px-3 h-100">
            <div className="d-flex justify-content-between align-items-center">
              {params.subStep !== 'solicitacao-recebida' && (
                <>
                  <div>
                    <h2 className="fw-normal h5 mb-3">{title}</h2>
                    <h3 className="fw-semibold h4">{question}</h3>
                  </div>
                  {params.subStep !== 'que-tipo-de-projeto-voce-deseja' &&
                    params.step !== 'dados-para-contato' && (
                      <button
                        type="button"
                        className="border-0 bg-transparent fw-semibold btn-skip d-none d-lg-block"
                        onClick={() => handleClickSkip(budget)}
                      >
                        Pular
                      </button>
                    )}
                </>
              )}
            </div>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className={`w-100 d-flex flex-column justify-content-between ${
                params.step === 'informacoes-adicionais' ? '' : 'mt-5'
              }`}
            >
              {params.subStep !== 'solicitacao-recebida' ? (
                <>
                  {(params.step === 'sobre-seu-projeto' ||
                    params.step === 'informacoes-adicionais') && (
                    <div>
                      <InputCheckbox
                        type={
                          params.subStep &&
                          params.subStep === 'que-tipo-de-projeto-voce-deseja'
                            ? 'checkbox'
                            : 'radio'
                        }
                        name="checkbox"
                        options={options}
                        className="flex-wrap justify-content-start checkboxes"
                        onChange={handleChange}
                        error={checkBoxError}
                      />
                      {params.step === 'sobre-seu-projeto' && (
                        <div className="row mt-5 px-3">
                          {options.filter(
                            (option) =>
                              option.value === 'Sim' && option.selected
                          ).length > 0 && (
                            <>
                              {params.subStep ===
                                'voce-possui-dominio-web-url-para-seu-projeto' && (
                                <div className="col-lg-5 px-0 px-lg-3 mb-5 mb-lg-0">
                                  <label className="d-block">
                                    <span className="d-block mb-3">
                                      Qual é a sua URL:
                                    </span>
                                    <Input
                                      name="domain"
                                      placeholder="Digite aqui"
                                      className="input"
                                    />
                                  </label>
                                </div>
                              )}
                              {params.subStep ===
                                'voce-possui-servidor-para-seu-projeto' && (
                                <div className="col-lg-5 px-0 px-lg-3 mb-5 mb-lg-0">
                                  <label className="d-block">
                                    <span className="d-block mb-3">
                                      Qual é o nome do servidor?
                                    </span>
                                    <Input
                                      name="server"
                                      placeholder="Digite aqui"
                                      className="input"
                                    />
                                  </label>
                                </div>
                              )}
                            </>
                          )}
                          {params.subStep ===
                            'em-quanto-tempo-precisa-desse-projeto-pronto' &&
                            options.find(
                              (option) =>
                                option.value === 'Outro' && option.selected
                            ) && (
                              <div className="col-lg-5 px-0 px-lg-3 mb-5 mb-lg-0">
                                <label className="d-block">
                                  <span className="d-block mb-3">
                                    Qual seu prazo?
                                  </span>
                                  <Input name="deadline" className="input" />
                                </label>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                  {params.step === 'dados-para-contato' && (
                    <div className="row">
                      <div className="col-lg-5 mb-3 mb-lg-5">
                        <label className="d-block">
                          <span className="d-block mb-3">Nome:</span>
                          <Input
                            name="name"
                            placeholder="Digite aqui"
                            className="input"
                          />
                        </label>
                      </div>
                      <div className="col-lg-5 mb-3 mb-lg-5">
                        <label className="d-block">
                          <span className="d-block mb-3">E-mail:</span>
                          <Input
                            type="email"
                            name="email"
                            placeholder="Digite aqui"
                            className="input"
                          />
                        </label>
                      </div>
                      <div className="col-lg-5 mb-5">
                        <label className="d-block">
                          <span className="d-block mb-3">
                            Telefone: <small>(opcional)</small>
                          </span>
                          <InputMask
                            kind="cel-phone"
                            name="celphone"
                            placeholder="(00) 0000-0000"
                            className="input"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  {params.step === 'informacoes-adicionais' &&
                    options.filter(
                      (option) => option.value === 'Sim' && option.selected
                    ).length > 0 && (
                      <div className="row">
                        <div className="col-lg-8 mb-5 mt-4 mt-lg-0">
                          <label className="d-block">
                            <span className="d-block mb-3">
                              Caso queira, faça upload da sua inspiração
                            </span>
                          </label>
                          <InputFile
                            name="files"
                            onChange={handleChangeFiles}
                          />
                        </div>
                        <div className="col-lg-8 mb-5">
                          <label className="d-block">
                            <span className="d-block mb-3">
                              Alguma observação?
                            </span>
                            <Textarea
                              name="observation"
                              className="textarea p-3"
                              placeholder="Digite aqui"
                              rows={4}
                            />
                          </label>
                        </div>
                      </div>
                    )}
                </>
              ) : (
                <div className="d-flex flex-column align-items-start success">
                  <Lottie
                    options={
                      {
                        animationData: success,
                        autoplay: true,
                        loop: false,
                        speed: 0.1,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      } as unknown as Options
                    }
                    height={280}
                    width={280}
                  />
                  <h4 className="fw-semibold text-primary">
                    Informações enviadas <br />
                    com sucesso!
                  </h4>
                  <p className="h5 fw-normal">Em breve entraremos em contato</p>
                </div>
              )}
              <div className="d-flex justify-content-end">
                {params.subStep !== 'solicitacao-recebida' ? (
                  <>
                    {(params.step !== 'sobre-seu-projeto' ||
                      (params.subStep &&
                        params.subStep !==
                          'que-tipo-de-projeto-voce-deseja')) && (
                      <button
                        type="button"
                        className="btn btn-back"
                        onClick={handleClickBack}
                      >
                        Voltar
                      </button>
                    )}
                    <button type="submit" className="btn btn-submit">
                      Enviar <IoIosArrowForward size={24} color="#fff" />
                    </button>
                  </>
                ) : (
                  <a
                    href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Estou%20com%20d%C3%BAvidas%20pode%20me%20ajudar?"
                    className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-center mt-5 mt-lg-0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <RiWhatsappLine size={20} color="#FFFFFF" />
                    </span>
                    <span>
                      Alguma dúvida? <b>Contate-nos</b>
                    </span>
                  </a>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Loading
        active={loading}
        text="Enviando informações, por favor aguarde..."
      >
        <div className="loading-box d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border text-light" role="status" />
          <span className="d-block fw-bold">Carregando...</span>
        </div>
      </Loading>
    </Container>
  );
};

export default MakeBudget;
