import React from 'react';
import { RiLinkedinFill } from 'react-icons/ri';
import { IoLogoWhatsapp } from 'react-icons/io';
import { AiFillInstagram } from 'react-icons/ai';
import { BsWhatsapp } from 'react-icons/bs';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <div className="container pb-5 pb-lg-0">
        <div className="row">
          <div className="col-12">
            <div className="box px-3 py-4 p-lg-5 mb-5">
              <div className="row align-items-end mx-2">
                <div className="col-lg-6">
                  <h3 className="h4 h2-lg fw-semibold">
                    Vamos começar um <br className="d-none d-lg-block" />
                    projeto juntos?
                  </h3>

                  <div className="mt-4 mt-lg-5 pt-4">
                    <p className="mb-0">E-mail</p>
                    <a
                      href="mailto:contato@devsigner.com.br"
                      target="_blank"
                      rel="noreferrer"
                      className="fw-medium"
                    >
                      contato@devsigner.com.br
                    </a>
                  </div>
                  <div className="mt-4">
                    <p className="mb-0">WhatsApp</p>
                    <a
                      href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20um%20projeto."
                      target="_blank"
                      rel="noreferrer"
                      className="fw-medium"
                    >
                      +55 11 91309-6161
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-lg-center justify-content-end social-media mt-5 mt-lg-0">
                  <a
                    href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20um%20projeto."
                    target="_blank"
                    title="Whatsapp"
                    rel="noreferrer"
                  >
                    <IoLogoWhatsapp size={34} color="#5D5D5D" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/devsigner-systems/about/"
                    target="_blank"
                    title="LinkedIn"
                    rel="noreferrer"
                  >
                    <RiLinkedinFill size={34} color="#5D5D5D" />
                  </a>
                  <a
                    href="https://www.instagram.com/_devsigner/"
                    target="_blank"
                    title="Instagram"
                    rel="noreferrer"
                  >
                    <AiFillInstagram size={34} color="#5D5D5D" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20um%20projeto."
        target="blank"
        title="Whatsapp"
        className="position-fixed btn-wpp"
      >
        <BsWhatsapp color="#fff" />
      </a>
    </Container>
  );
};

export default Footer;
