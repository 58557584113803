import styled from 'styled-components';

interface IContainer {
  active: boolean;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000077;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  z-index: 1000;

  .anim-lottie {
    pointer-events: none;
  }

  p {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-right: -20px;
  }
`;
