import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { TbCheck } from 'react-icons/tb';
import { CgMathPlus } from 'react-icons/cg';
import { IoTriangleSharp } from 'react-icons/io5';
import { BsCheck } from 'react-icons/bs';
import { HiArrowUpRight } from 'react-icons/hi2';
import { Link } from 'react-router-dom';

import { RiWhatsappLine } from 'react-icons/ri';
import { IoIosArrowForward } from 'react-icons/io';

import { data } from 'browserslist';
import {
  Container,
  Hero,
  AboutUs,
  Portfolio,
  OurServices,
  OurProcess,
  Budget,
  Experience,
  Faq,
} from './styles';

import blueLogo from '~/assets/logos/blue-logo.svg';
import apps from '~/assets/defaults/apps.png';
import person from '~/assets/defaults/person.png';
import peoples from '~/assets/defaults/peoples.png';
import projectsImage from '~/assets/defaults/projects.png';
import sites from '~/assets/defaults/sites.svg';
import socialMedia from '~/assets/defaults/social-media.svg';
import appsVector from '~/assets/defaults/apps.svg';
import uiUxDesign from '~/assets/defaults/ui-ux-design.svg';
import sistems from '~/assets/defaults/sistems.svg';
import graphicDesign from '~/assets/defaults/graphic-design.svg';
import computer from '~/assets/banners/computer.png';
import api from '~/services/api';

interface IFile {
  file_url: string;
}

interface IProject {
  id: string;
  title: string;
  slug: string;
  files: IFile[];
}

const Home: React.FC = () => {
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const [returnTopActive, setReturnTopActive] = useState(false);
  const [step, setStep] = useState(1);
  const [question, setQuestion] = useState('');
  const [bigProject, setBigProject] = useState({} as IProject);
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    api
      .get<IProject[]>('projects', {
        params: {
          page: 'home',
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          const first = response.data.shift();
          setBigProject(first as IProject);
          setProjects(response.data);
        }
      });
  }, []);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (aboutUsRef.current) {
      setReturnTopActive(position >= aboutUsRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleClickQuestion = useCallback((questionData) => {
    setQuestion((state) => {
      if (state === questionData) {
        return '';
      }

      return questionData;
    });
  }, []);

  return (
    <Container id="inicio" returnTopActive={returnTopActive}>
      <Hero className="py-lg-5 container-xxl d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center align-content-between">
            <div className="col-lg-6 my-5 my-lg-0">
              <div className="py-xl-5 my-lg-5">
                <h1 className="pt-lg-5 pb-3 text-center text-lg-start px-2 px-lg-5">
                  <small className="h5 fw-medium d-flex justify-content-center text-center">
                    Vamos construir, juntos
                  </small>
                  <span className="fw-normal text-center d-block">
                    Sua grande <span className="fwnormal">ideia</span>{' '}
                    começa&nbsp;aqui
                  </span>
                </h1>
                <div className="scroll border-0 bg-transparent d-flex justify-content-center align-items-center">
                  Scroll para ver mais <span className="ms-2">|</span>
                </div>
              </div>
            </div>
            <div className="col-12 mt-n5 d-none d-lg-block">
              <div className="row align-items-end">
                <div className="col">
                  <div>
                    <img src={apps} alt="apps" className="w-100" />
                  </div>
                </div>
                <div className="col">
                  <div>
                    <img src={person} alt="person" className="w-100" />
                  </div>
                </div>
                <div className="col">
                  <div>
                    <button
                      type="button"
                      className="btn btn-idea w-100 d-flex flex-column align-items-center py-4"
                    >
                      <span className="d-flex justify-content-center align-items-center mb-3">
                        <CgMathPlus size={24} color="#0A4D9C" />
                      </span>
                      Iniciar uma ideia!
                    </button>
                  </div>
                </div>
                <div className="col">
                  <div>
                    <img src={peoples} alt="peoples" className="w-100" />
                  </div>
                </div>
                <div className="col">
                  <div className="position-relative">
                    <img src={projectsImage} alt="projects" className="w-100" />
                    <div className="position-absolute px-4 pt-4 pb-3 d-flex flex-column justify-content-between h-100">
                      <p className="h3 fw-normal">
                        Cuidamos do <b className="fw-semibold">seu projeto</b>{' '}
                        como nosso
                      </p>
                      <div className="d-flex align-items-center px-3 py-2">
                        <div className="rounded-circle me-2 d-flex align-items-center justify-content-center">
                          <BsCheck size={24} color="#fff" />
                        </div>
                        <p className="mb-0">Qualidade do mercado</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-block d-lg-none">
              <div className="row mb-5">
                <div className="col">
                  <div>
                    <button
                      type="button"
                      className="btn btn-idea w-100 w-md-50 mx-auto d-flex justify-content-center align-items-center rounded-pill py-2"
                    >
                      Iniciar uma ideia!
                      <span className="d-flex justify-content-center align-items-center rounded-circle ms-2">
                        <CgMathPlus size={12} color="#0A4D9C" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col pe-0">
                  <div>
                    <img src={apps} alt="apps" className="w-100" />
                  </div>
                </div>
                <div className="col">
                  <div>
                    <img src={peoples} alt="peoples" className="w-100" />
                  </div>
                </div>
                <div className="col ps-0">
                  <div className="position-relative">
                    <img src={projectsImage} alt="projects" className="w-100" />
                    <div className="position-absolute p-2 px-lg-4 pt-lg-4 pb-lg-3 d-flex flex-column justify-content-between h-100">
                      <p className="h3 fw-normal">
                        Cuidamos
                        <br /> do{' '}
                        <b className="fw-semibold">
                          seu
                          <br /> projeto
                        </b>
                        <br /> como nosso
                      </p>
                      <div className="d-flex align-items-center p-1 px-lg-3 py-lg-2">
                        <div className="rounded-circle me-1 me-lg-2 d-flex align-items-center justify-content-center">
                          <BsCheck size={10} color="#fff" />
                        </div>
                        <p className="mb-0">Qualidade do mercado</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <AboutUs ref={aboutUsRef} id="quem-somos">
        <div className="container">
          <div className="row pb-5 py-lg-5">
            <div className="col-12 mb-4 mb-lg-5">
              <h2 className="h3 h1-lg fw-normal text-center text-primary">
                Sobre a Devsigner
              </h2>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-0">
              <p className="h4-lg fw-light text-secondary px-lg-3">
                Somos uma empresa especializada no desenvolvimento de projetos
                digitais de alta performance, com foco em simplicidade
                e&nbsp;conversão.
              </p>
            </div>
            <div className="col-lg-6">
              <p className="h4-lg fw-light text-secondary px-lg-3">
                Reunimos profissionais criativos,{' '}
                <b className="fw-medium">
                  qualificados e com anos de experiência
                </b>{' '}
                para{' '}
                <b className="fw-medium">
                  garantirmos excelência em cada etapa do projeto.
                </b>
              </p>
            </div>
          </div>
        </div>
      </AboutUs>
      <Portfolio>
        <div className="container">
          <div className="row">
            <div className="col-12 my-4 mb-lg-5">
              <h2 className="h3 h1-lg fw-normal text-center text-primary">
                Nossos projetos
              </h2>
            </div>
            {Object.keys(bigProject).length > 0 && (
              <Link
                to={`${process.env.PUBLIC_URL}/projetos/${bigProject.slug}`}
                className="col-lg-6 mb-4 mb-lg-0"
              >
                <img
                  src={bigProject.files[0].file_url}
                  alt={bigProject.title}
                  className="w-100"
                />
              </Link>
            )}
            <div className="col-lg-6 other-projects">
              <div className="row">
                {console.log(projects)}
                {projects.map((project) => (
                  <Link
                    key={project.id}
                    to={`${process.env.PUBLIC_URL}/projetos/${project.slug}`}
                    className="col-lg-6 mb-4"
                  >
                    <img
                      src={project.files[0].file_url}
                      alt={project.title}
                      className="w-100"
                    />
                  </Link>
                ))}
              </div>
            </div>
            <div className="col-12 my-5">
              <Link
                to="/"
                className="h4 fw-semibold text-center text-secondary d-block"
              >
                Ver mais
              </Link>
            </div>
          </div>
        </div>
      </Portfolio>
      <OurServices id="solucoes" className="position-relative">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="h3 h1-lg fw-normal text-center text-primary">
                Serviços que a Devsigner <br className="d-none d-lg-block" />
                oferece
              </h2>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <img src={sites} alt="Sites" className="ps-lg-4" />
                <h3 className="text-primary fw-semibold h5 mt-4 mb-3">Sites</h3>
                <p className="text-secondary text-center">
                  Passe credibilidade para <br />
                  seus clientes
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <img src={socialMedia} alt="Mídia social" className="ps-lg-2" />
                <h3 className="text-primary fw-semibold h5 mt-4 mb-3">
                  Mídia social
                </h3>
                <p className="text-secondary text-center">
                  Comunique-se e engaje <br />
                  seus usuários
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <img src={appsVector} alt="Aplicativos" className="ps-lg-4" />
                <h3 className="text-primary fw-semibold h5 mt-4 mb-3">
                  Aplicativos
                </h3>
                <p className="text-secondary text-center">
                  As funcionalidades <br />
                  avançadas para seu negócio
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <img src={uiUxDesign} alt="UI | UX Design" />
                <h3 className="text-primary fw-semibold h5 mt-4 mb-3">
                  Interface e experiência do usuário
                </h3>
                <p className="text-secondary text-center">
                  Aprimore a simplicidade e <br />
                  satisfação do usuário
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <img src={sistems} alt="Sistemas" />
                <h3 className="text-primary fw-semibold h5 mt-4 mb-3">
                  Sistemas
                </h3>
                <p className="text-secondary text-center">
                  Automatize e controle seu <br />
                  negócio com tecnologia
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <img src={graphicDesign} alt="Artes Gráficas" />
                <h3 className="text-primary fw-semibold h5 mt-4 mb-3">
                  Artes Gráficas
                </h3>
                <p className="text-secondary text-center">
                  Design visual personalizado <br />
                  para seus produtos.
                </p>
              </div>
            </div>
            <div className="col-12">
              <Link
                to={`${process.env.PUBLIC_URL}/quero-um-projeto/sobre-seu-projeto/que-tipo-de-projeto-voce-deseja`}
                className="btn btn-budget rounded-pill mx-auto d-block px-4 py-2 w-75 w-md-50 w-lg-25"
              >
                Quero um projeto
              </Link>
            </div>
          </div>
        </div>
      </OurServices>
      <OurProcess>
        <div className="container mb-3">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="h3 h1-lg fw-normal text-center text-primary">
                Entenda nosso processo
              </h2>
            </div>
            <div className="col-12">
              <div className="box p-4 p-lg-5">
                <div className="row align-items-center">
                  <div className="col-lg-3 overflow-auto mb-3 pb-3 mb-lg-0 pb-lg-0">
                    <div className="d-flex align-items-center flex-lg-column step-buttons">
                      <button
                        type="button"
                        className={`btn btn-step mb-lg-3 px-3 w-lg-100 ${
                          step === 1 ? 'active' : ''
                        }`}
                        onClick={() => setStep(1)}
                      >
                        1. Start
                      </button>
                      <IoIosArrowForward
                        size={24}
                        color="#CDCDCD"
                        className="d-block d-lg-none"
                      />
                      <button
                        type="button"
                        className={`btn btn-step mb-lg-3 px-3 w-lg-100 ${
                          step === 2 ? 'active' : ''
                        }`}
                        onClick={() => setStep(2)}
                      >
                        2. Design
                      </button>
                      <IoIosArrowForward
                        size={24}
                        color="#CDCDCD"
                        className="d-block d-lg-none"
                      />
                      <button
                        type="button"
                        className={`btn btn-step mb-lg-3 px-3 w-lg-100 ${
                          step === 3 ? 'active' : ''
                        }`}
                        onClick={() => setStep(3)}
                      >
                        3. Desenvolvimento
                      </button>
                      <IoIosArrowForward
                        size={24}
                        color="#CDCDCD"
                        className="d-block d-lg-none"
                      />
                      <button
                        type="button"
                        className={`btn btn-step mb-lg-3 px-3 w-lg-100 ${
                          step === 4 ? 'active' : ''
                        }`}
                        onClick={() => setStep(4)}
                      >
                        4. Finalização
                      </button>
                    </div>
                  </div>
                  {step === 1 && (
                    <div className="col-lg-7">
                      <h3 className="h4 h2-lg fw-semibold text-primary mb-n2">
                        Start
                      </h3>
                      <p className="h4-lg fw-normal text-secondary mt-5">
                        Começamos organizando todas as informações e
                        necessidades do seu projeto.
                      </p>
                      <div className="d-flex flex-wrap tags mt-4">
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          UX Research
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Sitemap
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Benchmarking
                        </span>
                      </div>
                    </div>
                  )}
                  {step === 2 && (
                    <div className="col-lg-7">
                      <h3 className="h4 h2-lg fw-semibold text-primary mb-n2">
                        Design
                      </h3>
                      <p className="h4-lg fw-normal text-secondary mt-5">
                        Nós materializamos sua ideia em um protótipo, onde você
                        poderá navegar e avaliar.
                      </p>
                      <div className="d-flex flex-wrap tags mt-4">
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          UX Writing
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Wireframes
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          UI & UX Design
                        </span>
                        <span className="mb-3 mb-lg-0 mt-lg-3">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Prototipação
                        </span>
                      </div>
                    </div>
                  )}
                  {step === 3 && (
                    <div className="col-lg-7">
                      <h3 className="h4 h2-lg fw-semibold text-primary mb-n2">
                        Desenvolvimento
                      </h3>
                      <p className="h4-lg fw-normal text-secondary mt-5">
                        Com o protótipo aprovado, é hora de passar o seu projeto
                        para a programação.
                      </p>
                      <div className="d-flex flex-wrap tags mt-4">
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Front-end
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Back-end
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          SEO
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Integrações
                        </span>
                      </div>
                    </div>
                  )}
                  {step === 4 && (
                    <div className="col-lg-7">
                      <h3 className="h4 h2-lg fw-semibold text-primary mb-n2">
                        Finalização
                      </h3>
                      <p className="h4-lg fw-normal text-secondary mt-5">
                        Seu projeto de alta performance será entregue
                        funcionando perfeitamente.
                      </p>
                      <div className="d-flex flex-wrap tags mt-4">
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Testes
                        </span>
                        <span className="mb-3 mb-lg-0">
                          <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                          Monitoramento
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </OurProcess>
      <Budget className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="box py-5">
                <h2 className="text-white fw-semibold text-center">
                  Gostaria de fazer <br />
                  um orçamento?
                </h2>
                <p className="h5 text-white text-center my-5">
                  Tenha projetos personalizados e <br />
                  funcionais para seus usuários.
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/quero-um-projeto/sobre-seu-projeto/que-tipo-de-projeto-voce-deseja`}
                  className="btn btn-budget rounded-pill mx-auto d-block px-4 py-2 w-75 w-md-50 w-lg-25"
                >
                  Quero um projeto
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Budget>
      <Experience>
        <div className="container py-5">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="box px-3 pt-4 p-lg-5">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6 order-1 order-lg-0 pb-5 pb-lg-0">
                    <h2 className="mb-5 h3 h1-lg fw-normal text-primary">
                      Aprendemos com <br />
                      cada experiência
                    </h2>
                    <div className="d-flex align-items-center">
                      <p className="h5-lg text-secondary">
                        Encaramos cada projeto como uma{' '}
                        <b className="fw-semibold">
                          nova <br className="d-none d-lg-block" />
                          parceria
                        </b>{' '}
                        e nos certificamos de{' '}
                        <b className="fw-semibold">
                          facilitar <br className="d-none d-lg-block" />
                          todos os processos
                        </b>
                        , mesmo quando <br className="d-none d-lg-block" />
                        enfrentamos os problemas mais complexos.
                      </p>
                    </div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/quero-um-projeto/sobre-seu-projeto/que-tipo-de-projeto-voce-deseja`}
                      className="btn btn-budget rounded-pill px-4 py-2 mt-4 w-75 w-md-50"
                    >
                      Quero um projeto
                    </Link>
                  </div>
                  <div className="col-lg-6 text-center mb-5 mb-lg-0 order-0 order-lg-1">
                    <img
                      src={computer}
                      alt="computer"
                      className="w-100"
                      title="Aprendemos com cada experiência"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Experience>
      <Faq>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="h4 h3-lg fw-normal">Perguntas frequentes</h2>
              <h3 className="h2 fw-semibold text-primary my-4">FAQ</h3>
              <p className="h5-lg fw-normal text-secondary mb-4 mb-lg-5 pb-lg-5 pt-lg-5">
                Para orçamentos e dúvidas, entre em{' '}
                <br className="d-none d-lg-block" />
                contato conosco. Estamos á disposição{' '}
                <br className="d-none d-lg-block" />
                para atendê-lo da melhor forma possível.
              </p>
              <a
                href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Estou%20com%20d%C3%BAvidas%20pode%20me%20ajudar?"
                className="rounded-pill px-3 py-2 d-none d-lg-flex align-items-center justify-content-center"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <RiWhatsappLine size={20} color="#FFFFFF" />
                </span>
                <span>
                  Alguma dúvida? <b>Contate-nos</b>
                </span>
              </a>
            </div>
            <div className="col-lg-6">
              <div className="question">
                <button
                  type="button"
                  className={`btn btn-faq d-flex align-items-center w-100 text-start ${
                    question === 'Qual o prazo de entrega?' ? 'opened' : ''
                  }`}
                  onClick={() =>
                    handleClickQuestion('Qual o prazo de entrega?')
                  }
                >
                  <IoTriangleSharp
                    size={21}
                    color="#ADCEF4"
                    className="ms-2 me-3"
                  />
                  <span>Qual o prazo de entrega?</span>
                </button>
                <div
                  className={`answer ${
                    question === 'Qual o prazo de entrega?' ? 'opened' : ''
                  }`}
                >
                  <div
                    className={`p-4 ${
                      question === 'Qual o prazo de entrega?' ? 'opened' : ''
                    }`}
                  >
                    <p>
                      Depende muito do produto que deseja, mas para o caso de
                      uma landpage, pode-se entregar em até um mês.
                    </p>
                  </div>
                </div>
              </div>
              <div className="question my-3">
                <button
                  type="button"
                  className={`btn btn-faq d-flex align-items-center w-100 text-start ${
                    question ===
                    'Qual ferramenta utiliza para desenvolver as paginas?'
                      ? 'opened'
                      : ''
                  }`}
                  onClick={() =>
                    handleClickQuestion(
                      'Qual ferramenta utiliza para desenvolver as paginas?'
                    )
                  }
                >
                  <IoTriangleSharp
                    size={21}
                    color="#ADCEF4"
                    className="ms-2 me-3"
                  />
                  <span>
                    Qual ferramenta utiliza para desenvolver as paginas?
                  </span>
                </button>
                <div
                  className={`answer ${
                    question ===
                    'Qual ferramenta utiliza para desenvolver as paginas?'
                      ? 'opened'
                      : ''
                  }`}
                >
                  <div className="p-4">
                    <p>
                      No caso de programação, usa-se React, React-Native,
                      Node.Js, PHP, entre outros.
                    </p>
                    <p>
                      Em casos de Design, usamos Figma, Adobe XD, Photoshop,
                      entre outros.
                    </p>
                  </div>
                </div>
              </div>
              <div className="question mb-3">
                <button
                  type="button"
                  className={`btn btn-faq d-flex align-items-center w-100 text-start ${
                    question === 'Preciso ter domínio e hospedagem?'
                      ? 'opened'
                      : ''
                  }`}
                  onClick={() =>
                    handleClickQuestion('Preciso ter domínio e hospedagem?')
                  }
                >
                  <IoTriangleSharp
                    size={21}
                    color="#ADCEF4"
                    className="ms-2 me-3"
                  />
                  <span>Preciso ter domínio e hospedagem?</span>
                </button>
                <div
                  className={`answer ${
                    question === 'Preciso ter domínio e hospedagem?'
                      ? 'opened'
                      : ''
                  }`}
                >
                  <div className="p-4">
                    <p>
                      Sim, é necessário ter um domínio para ter um site online.
                      Podemos cuidar de todo o processo de gerenciamento e
                      contratação do domínio para você, arcando apenas com os
                      custos do domínio. Assim, seu site estará sempre acessível
                      na internet.
                    </p>
                  </div>
                </div>
              </div>
              <div className="question">
                <button
                  type="button"
                  className={`btn btn-faq d-flex align-items-center w-100 text-start ${
                    question === 'As paginas sao otimizadas e responsivas?'
                      ? 'opened'
                      : ''
                  }`}
                  onClick={() =>
                    handleClickQuestion(
                      'As paginas sao otimizadas e responsivas?'
                    )
                  }
                >
                  <IoTriangleSharp
                    size={21}
                    color="#ADCEF4"
                    className="ms-2 me-3"
                  />
                  <span>As paginas sao otimizadas e responsivas?</span>
                </button>
                <div
                  className={`answer ${
                    question === 'As paginas sao otimizadas e responsivas?'
                      ? 'opened'
                      : ''
                  }`}
                >
                  <div className="p-4">
                    <p>Sim! No caso de sites, sistemas, landpage, etc.</p>
                    <p>São adaptáveis para todos dispositvos e navegadores</p>
                  </div>
                </div>
              </div>
              <a
                href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Estou%20com%20d%C3%BAvidas%20pode%20me%20ajudar?"
                className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-center d-block d-lg-none mt-4"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <RiWhatsappLine size={13} color="#FFFFFF" />
                </span>
                <span>
                  Alguma dúvida? <b>Contate-nos</b>
                </span>
              </a>
            </div>
          </div>
        </div>
      </Faq>
    </Container>
  );
};

export default Home;
