import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';
import Projects from '~/pages/Projects';
import Project from '~/pages/Project';
import Contato from '~/pages/Contato';
import MakeBudget from '~/pages/MakeBudget';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/solicitacao-recebida`}
        exact
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/projetos`}
        exact
        component={Projects}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/projetos/:slug`}
        exact
        component={Project}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/contato`}
        exact
        component={Contato}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/quero-um-projeto/:step`}
        exact
        component={MakeBudget}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/quero-um-projeto/:step/:subStep`}
        exact
        component={MakeBudget}
      />
    </Switch>
  );
};

export default routes;
