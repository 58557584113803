import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f8f9fd;
  min-height: 100vh;

  .box {
    position: relative;
    border-radius: 16px;
    background: linear-gradient(0deg, #000 0%, #000 100%);
    flex-shrink: 0;

    > div {
      position: relative;
      z-index: 1;

      img {
        display: block;
        margin-left: auto;
      }

      .detail {
        width: 45px;
        height: 6px;
        border-radius: 19px;
        background: rgba(255, 255, 255, 0.67);
      }

      h1 {
        color: #ffffff;
      }

      > div {
        a {
          color: #ffffff;

          margin: 70px 0;

          span:nth-child(1) {
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.44);
          }
        }

        a.active {
          span:nth-child(1) {
            background: #17c85e;
          }
        }

        a:nth-child(1) {
          margin-top: 0;
        }

        a:nth-child(5) {
          margin-bottom: 0;
        }

        div {
          width: 2px;
          height: 59px;
          background-color: #fff;
          left: 20px;
        }

        .first {
          top: 47px;
        }

        .second {
          bottom: 47px;
        }
      }
    }

    ::before {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background: linear-gradient(
        180deg,
        rgba(97, 130, 215, 0) 0%,
        rgba(0, 137, 156, 0.2) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
    }

    ::after {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background: linear-gradient(180deg, #0462ca 0%, #2acaed 100%);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .circle {
    width: 148px;
    height: 60px;

    .graph {
      width: 180px;
      height: 80px;
      left: -40px;
    }

    > small {
      left: 34px;
      top: 49%;
      transform: translateY(-50%);
      color: #3a618e;
    }
  }

  h2 {
    color: #637e9e;
  }

  .btn-skip {
    color: #0075ff;
  }

  form {
    height: calc(100% - 116.8px);
    padding-bottom: 15px;

    .checkboxes {
      label {
        width: 100%;
        padding: 16px 24px;
        border-radius: 16px;
        border: 1px solid #dbe1eb;
        background: #fff;
        margin: 12px 0;

        > div {
          width: 23px;
          height: 23px;
          border-radius: 7px;
          border: 1px solid #8a8f9e;
          background-color: transparent;
          transition-duration: 0.3s;

          svg {
            width: 17px;
            height: 17px;
          }
        }

        > div.checked {
          background: #0075ff;
          border: 1px solid #0075ff;
        }

        span {
          width: calc(100% - 31px);
        }
      }
    }

    label {
      span {
        color: #637e9e;
      }

      .input {
        border-radius: 16px;
        border: 1px solid #dbe1eb;
        background: #fff;
        height: 56px;

        input {
          color: #202020;

          ::placeholder {
            color: #93a9c3;
          }
        }
      }

      .textarea {
        border-radius: 16px;
        border: 1px solid #dbe1eb;
        background: #fff;

        textarea {
          color: #202020;

          ::placeholder {
            color: #93a9c3;
          }
        }
      }
    }

    .btn-back {
      border-radius: 16px;
      background: #c5dfff;
      color: #0063d7;
      width: 136px;
      height: 47px;
    }

    .btn-submit {
      border-radius: 16px;
      background: #0075ff;
      color: #fff;
      width: 136px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
    }

    a {
      border: 1px solid #dbe1eb;
      width: 352px;

      span:nth-child(1) {
        width: 24px;
        height: 24px;
        background: #0be094;
        border-radius: 50%;
        margin-right: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span:nth-child(2) {
        color: #637e9e;
        font-size: 12px;

        b {
          font-size: 16px;
          color: #304762;
        }
      }
    }

    .success {
      > div {
        margin: 0 !important;
        pointer-events: none;
      }
    }
  }

  @media screen and (min-width: 992px) {
    form {
      .checkboxes {
        label {
          width: auto;
          margin: 12px;
        }
      }
    }
  }
`;
