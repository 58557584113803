import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Img } from './styles';

import bannerQ540 from '~/assets/banners/banner-q540.png';
import logoQ540 from '~/assets/logos/white-q540-logo.svg';
import api from '~/services/api';
import Project from '../Project';

interface IFile {
  type: string;
  file_url: string;
}

interface IProjectResponse {
  id: string;
  title: string;
  type: string;
  slug: string;
  files: IFile[];
}
interface IProject {
  id: string;
  title: string;
  type: string;
  logo: string;
  thumbnail: string;
  slug: string;
}

const Projects: React.FC = () => {
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    api
      .get<IProjectResponse[]>('projects', {
        params: {
          page: 'projects',
        },
      })
      .then((response) => {
        const data: IProject[] = response.data.map((project) => {
          const logo = project.files.find((file) => file.type === 'logo');
          const thumbnail = project.files.find(
            (file) => file.type === 'thumbnail'
          );

          return {
            id: project.id,
            title: project.title,
            type: project.type,
            logo: logo ? logo.file_url : '',
            thumbnail: thumbnail ? thumbnail.file_url : '',
            slug: project.slug,
          };
        });
        console.log(data);
        setProjects(data);
      });
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="h6 h5-lg fw-medium text-center">
              Produzidos com entusiasmo
            </h1>
            <h2 className="mb-5 h3 h1-lg fw-normal text-primary text-center">
              Projetos
            </h2>
          </div>
          {projects.map((project) => (
            <div key={project.id} className="col-lg-4">
              <Link to={`${process.env.PUBLIC_URL}/projetos/${project.slug}`}>
                <Img src={project.thumbnail}>
                  <p>{project.type}</p>
                </Img>
                <div className="d-flex align-items-center mt-2 mb-5">
                  <div className="logo rounded-circle">
                    <img src={project.logo} alt={`Logo ${project.title}`} />
                  </div>
                  <p className="mb-0 ms-2 fw-medium text-primary">
                    {project.title}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Projects;
