import React, { useCallback, useEffect, useState } from 'react';
import { BsPaperclip } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';

import { HiOutlineTrash } from 'react-icons/hi2';
import { CgSoftwareUpload } from 'react-icons/cg';
import { Container, Dropzone } from './styles';
import Input from '~/components/Input';

interface IInputFile {
  name: string;
  className?: string;
  onChange?(files: File[]): void;
  value?: string;
}

const InputFile: React.FC<IInputFile> = ({
  name,
  className,
  onChange,
  value,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [valuePreview, setValuePreview] = useState('');
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (value) {
      setValuePreview(value);
    }
  }, [value]);

  const handleChange = useCallback((e) => {
    setFiles((state) => [...state, ...e.target.files]);
  }, []);

  useEffect(() => {
    setFiles((state) => [...state, ...acceptedFiles]);
  }, [acceptedFiles]);

  useEffect(() => {
    if (onChange) {
      onChange(files);
    }
  }, [files, onChange]);

  const handleClickRemove = useCallback((fileIndex) => {
    setFiles((state) => state.filter((_, index) => index !== fileIndex));
  }, []);

  return (
    <Container
      className={className}
      isErrored={isError}
      isFilled={isFilled}
      isFocuses={isFocuses}
      active={files.length > 0}
    >
      <label
        htmlFor={name}
        className={`d-flex align-items-center ${
          files.length === 0 ? 'justify-content-center' : 'bg-blue px-3 py-2'
        } w-100`}
      >
        <Dropzone
          {...getRootProps({ className: 'w-100' })}
          onClick={(event) => event.stopPropagation()}
        >
          <input {...getInputProps()} name={name} />
          <div className="d-flex align-items-center box-label">
            <CgSoftwareUpload
              size={files.length === 0 ? 40 : 24}
              color={files.length === 0 ? '#4282CD' : '#fff'}
              className={files.length === 0 ? 'ms-lg-3 me-lg-n4' : ''}
            />
            <p
              className={`mb-0 w-100 ${
                files.length === 0 ? 'text-lg-center ms-lg-n5' : 'text-start'
              }`}
            >
              {files.length > 0
                ? 'Adicionar ou arrastar aqui'
                : 'Clique aqui ou arraste e solte uma imagem'}
            </p>
          </div>
        </Dropzone>
        <Input
          type="file"
          name={name}
          id={name}
          multiple
          className="d-none"
          onChange={handleChange}
        />
      </label>
      {files.length > 0 && (
        <div className="mt-2 px-3 me-2">
          {files.map((file, index) => (
            <div className="d-flex align-items-center py-2">
              <p className="mb-0">{file.name}</p>
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={() => handleClickRemove(index)}
              >
                <HiOutlineTrash size={24} color="#F3465B" />
              </button>
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

export default InputFile;
