import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 100px;

  a {
    padding: 16px 40px;
    background: #f9f9f9;
    border: 1px solid #e6e0e0;
    border-radius: 20px;
    transition-duration: 0.3s;

    .icon {
      width: 58px;
      height: 58px;
      border-radius: 20px;
    }

    :hover {
      background: #e6e0e0;
    }
  }

  .col-lg-4:nth-child(3) .icon {
    background: #47e398;
  }

  .col-lg-4:nth-child(4) .icon {
    background: #e347d3;
  }

  .col-lg-4:nth-child(5) .icon {
    background: #0075ff;
  }
`;
