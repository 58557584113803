import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import SliderSlick, { Settings } from 'react-slick';

import { Container, Item, ButtonLeft, ButtonRight } from './styles';

interface ICarousel {
  className?: string;
  children?: any;
}

interface IButtonProps {
  className?: string;
  onClick?(): void;
}

const PrevArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonLeft
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowBack size={24} />
      </div>
    </ButtonLeft>
  );
};

const NextArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonRight
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowForward size={24} />
      </div>
    </ButtonRight>
  );
};

const Carousel: React.FC<ICarousel> = ({ className, children }) => {
  const sliderSlickRef = useRef<SliderSlick>(null);

  const handleClickPrevious = useCallback(() => {
    sliderSlickRef.current?.slickPrev();
  }, []);

  const handleClickNext = useCallback(() => {
    sliderSlickRef.current?.slickNext();
  }, []);

  const settings: Settings = useMemo(
    () => ({
      infinite: true,
      slidesToShow: 4,
      centerPadding: '0px',
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: false,
      touchMove: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 3,
            centerPadding: '0px',
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            centerPadding: '0px',
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            centerMode: false,
            centerPadding: '25px',
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            centerPadding: '25px',
            autoplay: true,
          },
        },
        {
          breakpoint: 565,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            centerPadding: '25px',
            autoplay: true,
          },
        },
      ],
    }),
    []
  );

  return (
    <Container className={className}>
      <SliderSlick ref={sliderSlickRef} {...settings}>
        {children}
      </SliderSlick>
      <div className="d-flex align-items-end justify-content-end mt-4">
        <button
          type="button"
          className="btn-prev mx-2"
          onClick={handleClickPrevious}
        >
          <IoIosArrowBack size={24} />
        </button>
        <button
          type="button"
          className="btn-next mx-2"
          onClick={handleClickNext}
        >
          <IoIosArrowForward size={24} />
        </button>
      </div>
    </Container>
  );
};

export default Carousel;
