import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

const AuthLayout: React.FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {!location.pathname.includes('quero-um-projeto') && <Header />}
      {children}
      {!location.pathname.includes('quero-um-projeto') && <Footer />}
    </>
  );
};

export default AuthLayout;
