import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.footer`
  .box {
    background: #f8f8f8;
    border-radius: 20px;

    h3 {
      line-height: 36px;
      color: #5e5e5e;
    }

    p {
      color: #707070;
      line-height: 24px;
    }

    a {
      color: #5e5e5e;
      line-height: 27px;
    }
  }

  .social-media {
    a {
      .icon-footer {
        border-radius: 50%;
        width: 56px;
        height: 56px;
      }

      :nth-child(1) {
        .icon-footer {
          background-color: #e2171c;
        }
      }

      :nth-child(2) {
        .icon-footer {
          background-color: #fbe919;
        }
      }

      :nth-child(3) {
        .icon-footer {
          background-color: #0373a9;
        }
      }

      :nth-child(4) {
        .icon-footer {
          background-color: #6e2560;
        }
      }

      + a {
        margin-left: 16px;
      }
    }
  }

  .icon-whatsapp-call {
    background: #22de57;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    position: fixed;
    bottom: 100px;
    right: 20px;
  }

  .line-footer {
    border: 1px solid #464646;
  }

  .btn-wpp {
    transition-duration: 0.3s;
    background: #29d26e;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 32px;
      height: 32px;
    }

    :hover {
      background: ${darken(0.03, '#29d26e')};
    }
  }

  @media screen and (min-width: 992px) {
    .box {
      h3 {
        line-height: 48px;
      }
    }

    .icon-footer {
      width: 56px;
      height: 56px;
      svg {
        font-size: 28px;
      }
    }

    .icon-whatsapp-call {
      bottom: 10px;
      right: 100px;
    }

    .btn-wpp {
      width: 100px;
      height: 100px;

      svg {
        width: 50px;
        height: 50px;
      }
    }
  }
`;
