import styled from 'styled-components';

interface IImg {
  src: string;
}

export const Container = styled.div`
  margin-top: 100px;

  h1 {
    color: #7e93ad;
  }

  .logo {
    background-color: #0075ff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Img = styled.div<IImg>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #0075ff;
  height: 273px;
  width: 100%;
  border-radius: 20px;
  padding: 28px 25px;
  display: flex;
  justify-content: end;
  align-items: start;

  p {
    display: inline-block;
    background: rgba(32, 32, 32, 0.4);
    border: 1px solid #4f4f4f;
    backdrop-filter: blur(2px);
    border-radius: 8px;
    padding: 6px 8px;
    color: #ffffff;
    font-weight: 500;
  }
`;
