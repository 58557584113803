import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
  active: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 20px;
  border: 2px dashed #8aa4c3;
  background: #ebf1f9;
  padding: ${(props) => (props.active ? '0 0 5px' : '5px 10px')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  cursor: pointer;

  label {
    cursor: pointer;

    p {
      color: #68819f;
      font-weight: 400;
    }
  }

  .bg-blue {
    border-radius: 20px 20px 0px 0px;
    background: #4282cd;
    margin: -2px -2px 0;
    width: calc(100% + 4px) !important;

    p {
      color: #fff;
      font-weight: 600;
    }
  }

  > div {
    width: 100%;
    height: 80px;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar-thumb {
      background-color: #b2ceee;
    }

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
    }
  }

  p {
    width: calc(100% - 24px);
    color: #8692a6;
    font-weight: 500;
  }

  svg {
    margin-right: 16px;
    color: #8692a6;
    transition-duration: 0.2s;
  }

  /* .box-label {
    p {
      width: calc(100% - 60px);
    }
  } */

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #606060;
      border-color: #606060;
    `}
`;

export const Dropzone = styled.div``;
