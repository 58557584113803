import styled, { css } from 'styled-components';

interface IContainer {
  active: boolean;
  whiteMenu: boolean;
}

interface IMenuProps {
  active: boolean;
  whiteMenu: boolean;
}

export const Container = styled.header<IContainer>`
  top: 0;
  background: transparent;
  z-index: 100;

  .menu-fix {
    width: 100%;
    height: 65px;
    position: fixed;
    z-index: 1000;
    transition-duration: 0.3s;
    background: #ffffff;
    border-bottom: 1px solid #ececec;
    top: 0;
  }

  img {
    transition: 0.3s;
    height: 30px;
  }

  .btn-budget {
    font-weight: 500;
    background: #0075ff;
    color: #ffffff;
  }

  @media screen and (min-width: 991px) {
    .menu-fix {
      width: 95%;
      height: 97px;
      background: rgba(255, 255, 255, 0.7);
      border: 1px solid #f2f5fa;
      backdrop-filter: blur(10px);
      border-radius: 16px;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      height: unset;
    }
  }
`;

export const Menu = styled.div<IMenuProps>`
  > button {
    width: 25px;
    height: 25px;

    span {
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: #202020;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        margin: 6px 0px;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -15px;
          }
        `}
    }
  }

  .menu-group {
    transition-duration: 0.3s;
    width: 100%;
    position: absolute;
    left: ${(props) => (props.active ? 0 : 1000)}px;
    background-color: ${(props) => (props.active ? '#fff' : 'transparent')};
    min-height: calc(100vh - 65px);
    top: 65px;

    .menu {
      min-height: calc(100vh - 65px);

      .btn-menu {
        transition-duration: 0.3s;
        color: #000;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;
        -webkit-text-fill-color: #000;
        position: relative;

        :after {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          bottom: -10px;
          transition-duration: 0.3s;
        }
      }

      .btn-menu:hover,
      .btn-menu.active {
        -webkit-text-stroke-color: #000;

        :after {
          background-color: #000;
        }
      }
    }
  }

  @media screen and (min-width: 991px) {
    .menu-group {
      width: auto;
      position: unset;
      background-color: transparent;
      min-height: unset;

      .menu {
        min-height: unset;
        .btn-menu {
          color: #202020;
          -webkit-text-fill-color: #202020;

          :after {
            content: '';
            position: absolute;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            bottom: -10px;
            transition-duration: 0.3s;
          }
        }

        .btn-menu:hover,
        .btn-menu.active {
          -webkit-text-fill-color: #202020;

          :after {
            background-color: #202020;
          }
        }
      }
    }
  }
`;
