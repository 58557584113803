import { darken } from 'polished';
import styled from 'styled-components';

import budget from '~/assets/banners/budget.png';

interface IContainer {
  returnTopActive: boolean;
}

export const Container = styled.div<IContainer>``;

export const Hero = styled.div`
  min-height: 100vh;

  h1 {
    small {
      color: #7e93ad;
    }

    > span {
      color: #202020;

      span {
        background: linear-gradient(135deg, #0075ff 32.95%, #6cd7e6 72.66%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  .scroll {
    transition-duration: 0.3s;
    bottom: 50px;
    color: #858585;

    > span {
      border: 1px solid #0075ff;
      color: #0075ff;
      border-radius: 20px;
      height: 36px;
      width: 23px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.3s;
    }
  }

  .col {
    > div {
      width: 100%;
      overflow: hidden;
      border-radius: 8px;

      > div {
        top: 0;

        > p {
          font-size: 10px;
          line-height: 15px;
          color: #ffffff;
        }

        > div {
          background: rgba(32, 32, 32, 0.4);
          backdrop-filter: blur(2px);
          border-radius: 8px;

          > div {
            width: 14.38px;
            height: 14.38px;
            background: rgba(255, 255, 255, 0.4);
          }

          > p {
            color: #ffffff;
            line-height: 9px;
            font-size: 6px;
            width: calc(100% - 19px);
          }
        }
      }
    }

    .btn-idea {
      background-color: #0075ff;
      font-weight: 500;
      line-height: 24px;
      color: #ffffff;

      span {
        background: #ffffff;
        box-shadow: -17px 19px 32px rgba(7, 7, 7, 0.1);
        border-radius: 18px;
        width: 25px;
        height: 25px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .col {
      > div {
        border-radius: 20px;

        > div {
          > p {
            font-size: 24px;
            line-height: 36px;
          }

          > div {
            border-radius: 20px;

            > div {
              width: 39px;
              height: 39px;
            }

            > p {
              line-height: 21px;
              font-size: 14px;
              width: calc(100% - 50px);
            }
          }
        }
      }
      .btn-idea {
        span {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
`;

export const AboutUs = styled.div``;

export const Portfolio = styled.div`
  .wiserr {
    background: #f7f7f7;
    border: 1px solid #e4e4e4;
    box-shadow: 6px -2px 13px rgba(0, 0, 0, 0.02);
    border-radius: 20px;

    h3 {
      background: linear-gradient(90deg, #996dff 0%, #ff4343 30%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .screen {
      filter: drop-shadow(0px 4px 80px rgba(0, 0, 0, 0.25));
      backdrop-filter: blur(20px);
    }

    a {
      width: 40px;
      height: 40px;
      background: #d9d9d9;
      position: absolute;
      bottom: 30px;
      right: 0;
    }
  }

  .other-projects {
  }

  @media screen and (min-width: 992px) {
    .wiserr {
      a {
        position: unset;
        bottom: unset;
        right: unset;
      }
    }
  }
`;

export const OurServices = styled.div`
  .btn-budget {
    font-weight: 500;
    background: #0075ff;
    color: #ffffff;
  }

  .shadow {
    box-shadow: 1px 1px 13px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
`;

export const OurProcess = styled.div`
  .box {
    background: #f9f9f9;
    border-radius: 20px;

    .overflow-auto {
      ::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;
      }

      .step-buttons {
        width: max-content;

        .btn-step {
          text-align: left;
          width: max-content;
          transition-duration: 0.3s;
          border-radius: 8px;
          font-weight: 400;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: transparent;
          -webkit-text-fill-color: #202020;
        }

        .btn-step.active {
          background: #0075ff;
          -webkit-text-stroke-color: #ffffff;
          -webkit-text-fill-color: #ffffff;
          -webkit-text-stroke-width: 0.5px;
        }
      }
    }

    .tags {
      span {
        background: #ffffff;
        border: 1px solid #dfdede;
        border-radius: 15px;
        padding: 8px 16px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .btn-budget {
    font-weight: 500;
    background: #0075ff;
    color: #ffffff;
  }

  @media screen and (min-width: 992px) {
    .box {
      .step-buttons {
        width: unset;

        .btn-step {
          width: 215px;
        }

        .btn-step.active,
        .btn-step:hover {
          background: #0075ff;
          -webkit-text-stroke-color: #ffffff;
          -webkit-text-fill-color: #ffffff;
          -webkit-text-stroke-width: 0.5px;
        }
      }
    }
  }
`;

export const Budget = styled.div`
  .box {
    background-image: url(${budget});
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    overflow: hidden;

    .btn-budget {
      font-weight: 500;
      background: #0075ff;
      color: #ffffff;
    }
  }
`;

export const Experience = styled.div`
  .box {
    background: #fafafa;
    border-radius: 20px;

    p {
      line-height: 30px;
    }

    .btn-budget {
      font-weight: 500;
      background: #0075ff;
      color: #ffffff;
    }
  }
`;

export const Faq = styled.div`
  h2 {
    color: #0075ff;
  }

  a {
    border: 1px solid #dbe1eb;
    width: 100%;

    span:nth-child(1) {
      width: 24px;
      height: 24px;
      background: #0be094;
      border-radius: 50%;
      margin-right: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span:nth-child(2) {
      color: #637e9e;
      font-size: 12px;

      b {
        font-size: 16px;
        color: #304762;
      }
    }
  }

  .question {
    background: #f3f9ff;
    border-radius: 20px;

    .btn-faq {
      background: #f3f9ff;
      border-radius: 20px;
      height: 77px;
      color: #005cc8;
      transition-duration: 0.3s;

      svg {
        transition-duration: 0.3s;
        transform: rotateZ(90deg);
      }

      span {
        width: calc(100% - 25px);
      }
    }

    .btn-faq.opened,
    .btn-faq:hover {
      background: #d7e9ff;
    }

    .btn-faq.opened {
      svg {
        transform: rotateZ(180deg);
      }
    }

    .answer {
      transition-duration: 0.3s;
      height: 0;
      overflow: hidden;

      p {
        color: #204c81;
      }
    }

    :nth-child(1) {
      .answer.opened {
        height: 160px;
      }
    }

    :nth-child(2) {
      .answer.opened {
        height: 224px;
      }
    }

    :nth-child(3) {
      .answer.opened {
        height: 304px;
      }
    }

    :nth-child(4) {
      .answer.opened {
        height: 176px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    a {
      width: 352px;

      span:nth-child(1) {
        width: 36px;
        height: 36px;
        margin-right: 13px;
      }

      span:nth-child(2) {
        font-size: 16px;
      }
    }

    .question {
      :nth-child(1) {
        .answer.opened {
          height: 112px;
        }
      }

      :nth-child(2) {
        .answer.opened {
          height: 152px;
        }
      }

      :nth-child(3) {
        .answer.opened {
          height: 160px;
        }
      }

      :nth-child(4) {
        .answer.opened {
          height: 128px;
        }
      }
    }
  }
`;
