import styled from 'styled-components';

interface IImg {
  src: string;
}

export const Container = styled.div`
  margin-top: 100px;

  h1 {
    color: #7e93ad;
  }

  h2 {
    a {
      width: 28px;
      height: 28px;
    }
  }

  .logo {
    background-color: #0075ff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tags {
    span {
      background: #ffffff;
      border: 1px solid #dfdede;
      border-radius: 15px;
      padding: 8px 16px;
      margin-right: 19px;
    }
  }

  .btn-budget {
    font-weight: 500;
    background: #0075ff;
    color: #ffffff;
  }
`;
